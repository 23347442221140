import { parseWithZod } from "@conform-to/zod";
import { invariantResponse } from "@epic-web/invariant";
import { cssBundleHref } from "@remix-run/css-bundle";
import {
  type ActionFunctionArgs,
  type HeadersFunction,
  json,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
  redirect } from
"@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetchers,
  useLoaderData,
  useLocation } from
"@remix-run/react";
import { setUser, withSentry } from "@sentry/remix";
import { useEffect } from "react";
import { HoneypotProvider } from "remix-utils/honeypot/react";
import { z } from "zod";

import { APP_NAME } from "@/app/utils/consts";
import { prisma } from "@/app/utils/db.server";
import { getUser, logout } from "@/app/utils/server/auth.server";
import OrganizationSwitcherController from "@/app/utils/server/controllers/organization-switcher-controller.server";
import { honeypot } from "@/app/utils/server/honeypot.server";
import { getTheme, setTheme } from "@/app/utils/server/theme.server";
import { makeTimings, time } from "@/app/utils/server/timing.server";
import { getToast } from "@/app/utils/server/toast.server";
import { getPublicEnv } from "~/packages/env/src/env.server";

import { GeneralErrorBoundary } from "./components/error-boundary";
import { EpicProgress } from "./components/progress-bar";
import { useToast } from "./components/toaster";
import { href as iconsHref } from "./components/ui/icons/icon";
import { EpicToaster } from "./components/ui/sonner";
import sonnerStyles from "./components/ui/sonner.css?url";
import globalStyleSheetUrl from "./styles/global.css?url";
import tailwindStyleSheetUrl from "./styles/tailwind.css?url";
import { ClientHintCheck, getHints } from "./utils/client-hints";
import { ThemeContextProvider } from "./utils/context/theme/ThemeContext";
import useTheme from "./utils/context/theme/useTheme";
import * as gtag from "./utils/gtag.client";
import { combineHeaders, getDomainUrl } from "./utils/misc";
import { useNonce } from "./utils/nonce-provider";

export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  // Preload CSS as a resource to avoid render blocking
  { rel: `preload`, href: iconsHref, as: `image` },
  { rel: `preload`, href: tailwindStyleSheetUrl, as: `style` },
  { rel: `preload`, href: globalStyleSheetUrl, as: `style` },
  cssBundleHref ? { rel: `preload`, href: cssBundleHref, as: `style` } : null,
  { rel: `mask-icon`, href: `/favicons/mask-icon.svg` },
  {
    rel: `alternate icon`,
    type: `image/png`,
    href: `/favicons/favicon-32x32.png`
  },
  { rel: `apple-touch-icon`, href: `/favicons/apple-touch-icon.png` },
  {
    rel: `manifest`,
    href: `/site.webmanifest`,
    crossOrigin: `use-credentials`
  } as const, // necessary to make typescript happy
  //These should match the css preloads above to avoid css as render blocking resource
  { rel: `icon`, type: `image/svg+xml`, href: `/favicons/favicon.svg` },
  { rel: `stylesheet`, href: tailwindStyleSheetUrl },
  { rel: `stylesheet`, href: globalStyleSheetUrl },
  { rel: `stylesheet`, href: sonnerStyles },
  cssBundleHref ? { rel: `stylesheet`, href: cssBundleHref } : null].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: data ? APP_NAME : `Error | ${APP_NAME}` },
  { name: `robots`, content: `noindex` }];

};

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings(`root loader`);
  const userSavedOnCookie = await time(() => getUser(request), {
    timings,
    type: `getUserId`,
    desc: `getUserId in root`
  });

  const user = userSavedOnCookie?.id ?
  await time(
    prisma.user.findUnique({
      select: {
        id: true,
        givenName: true,
        middleName: true,
        familyName: true,
        email: true,
        secondaryEmail: true,
        pronouns: true,
        organization: {
          select: {
            id: true,
            name: true
          }
        },
        seniority: true,
        department: true,
        country: true,
        jobRole: {
          select: {
            id: true,
            name: true
          }
        },
        jobTitle: true,
        industry: {
          select: {
            id: true,
            name: true
          }
        },
        role: true,
        createdAt: true,
        updatedAt: true
      },
      where: { id: userSavedOnCookie.id }
    }),
    { timings, type: `find user`, desc: `find user in root` }
  ) :
  null;

  if (userSavedOnCookie && !user) {
    console.info(`something weird happened`);
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await logout({ request });
  }

  const { toast, headers: toastHeaders } = await getToast(request);
  const honeyProps = honeypot.getInputProps();

  return json(
    {
      user: {
        ...user,
        rbac: {
          permissions: userSavedOnCookie?.rbac?.permissions,
          roles: userSavedOnCookie?.rbac?.roles
        },
        profilePhoto: userSavedOnCookie?.profilePhoto
      },
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request)
        }
      },
      ENV: getPublicEnv(),
      toast,
      honeyProps
    },
    {
      headers: combineHeaders(
        { "Server-Timing": JSON.stringify(timings) },
        toastHeaders
      )
    }
  );
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  return {
    "Server-Timing": loaderHeaders.get(`Server-Timing`) ?? ``
  };
};

const ThemeFormSchema = z.object({
  theme: z.enum([`system`, `light`, `dark`])
});

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const intent = formData.get(`intent`);
  const previousUrl = request.headers.get(`Referer`) ?? `/`;

  switch (intent) {
    case `change-theme`:{
        const submission = parseWithZod(formData, {
          schema: ThemeFormSchema
        });

        invariantResponse(
          submission.status === `success`,
          `Invalid theme received`
        );

        const { theme } = submission.value;

        const responseInit = {
          headers: { "set-cookie": setTheme(theme) }
        };

        return redirect(String(previousUrl), responseInit);
      }
    case `switch-organization`:{
        return OrganizationSwitcherController.switchOrganization({
          request,
          formData
        });
      }
    case `un-switch-organization`:
      return OrganizationSwitcherController.unSwitchOrganization();
    default:
      return json({ error: `Invalid intent` }, { status: 400 });
  }
}

function Document({
  children,
  nonce,
  env = {}




}: {children: React.ReactNode;nonce: string;env?: Record<string, string>;}) {
  const TRACKING_ID = ENV.GA_TRACKING_ID;
  const HOTJAR_TRACKING_ID = ENV.HOTJAR_ID;

  const { themePreference, dataTheme } = useTheme();

  return (
    <html lang="en" className={themePreference} {...dataTheme}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<script
          nonce={nonce}
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${TRACKING_ID}`} />

				<script
          nonce={nonce}
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
  									function gtag(){dataLayer.push(arguments);}
  									gtag('js', new Date());

  									gtag('config', '${TRACKING_ID}');`
          }} />

				<script
          nonce={nonce}
          type="text/javascript"
          src={`//js.hsforms.net/forms/embed/v2.js`} />

				<script
          nonce={nonce}
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{
            __html: `hbspt.forms.create({
						region: "na1",
						portalId: "7819406",
						target: ".hubspot-form-content",
						formId: "56b47b0f-046e-4a8c-8b64-722e2e1907bd"
					});`
          }} />

				<Links />
				<script
          nonce={nonce}
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{
            __html: `(function(h,o,t,j,a,r){
							h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
							h._hjSettings={hjid:${HOTJAR_TRACKING_ID},hjsv:6};
							a=o.getElementsByTagName('head')[0];
							r=o.createElement('script');r.async=1;
							r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
							a.appendChild(r);
					})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
          }} />

			</head>
			<body>
				{children}

				<script
          nonce={nonce}
          // biome-ignore lint/security/noDangerouslySetInnerHtml: we trust this script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<LiveReload nonce={nonce} />
			</body>
		</html>);

}

function App() {
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();

  const { theme } = useTheme();

  useToast(data.toast);

  const TRACKING_ID = ENV.GA_TRACKING_ID;

  const { pathname } = useLocation();

  useEffect(() => {
    if (TRACKING_ID) {
      gtag.pageview(pathname, TRACKING_ID);
    }
  }, [pathname, TRACKING_ID]);

  useEffect(() => {
    if (data.user) {
      setUser({ id: data.user.id });
    } else {
      setUser(null);
    }
  }, [data.user]);

  return (
    <ThemeContextProvider>
			<Document nonce={nonce} env={data.ENV}>
				<Outlet />
				<EpicToaster position="top-center" richColors theme={theme} />
				<EpicProgress />
			</Document>
		</ThemeContextProvider>);

}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <HoneypotProvider {...data.honeyProps}>
			<App />
		</HoneypotProvider>);

}

export default withSentry(AppWithProviders);

export function useOptimisticThemeMode() {
  const fetchers = useFetchers();
  const themeFetcher = fetchers.find((f) => f.formAction === `/`);

  if (themeFetcher?.formData) {
    const submission = parseWithZod(themeFetcher.formData, {
      schema: ThemeFormSchema
    });

    if (submission.status === `success`) {
      return submission.value.theme;
    }
  }
}

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>);

}